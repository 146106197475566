import {Component} from 'react'
import ReactGA from 'react-ga'

class GoogleAnalytics extends Component {
  componentDidMount() {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, {
        debug: true, // eslint-disable-line
        // gaOptions: {userId: ''} // eslint-disable-line
      })
    }
  }

  componentWillReceiveProps() {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.pageview(window.location.pathname + window.location.search)
    }
  }

  render() {
    return null
  }
}

export default GoogleAnalytics
