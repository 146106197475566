import 'react-app-polyfill/ie9' // For IE 9-11 support
import 'react-app-polyfill/ie11' // For IE 11 support
import './polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import { I18nextProvider } from 'react-i18next'
import ErrorBoundary from './components/Airbrake/ErrorBoundary'

import i18n from './i18n'
import App from './App'

ReactDOM.render(
  <ErrorBoundary>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </ErrorBoundary>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
