import React, {Component} from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import Loadable from 'react-loadable'
import GoogleAnalytics from './components/GoogleAnalytics'

import './App.scss'

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>

// Containers
const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout'),
  loading
})

const Page404 = Loadable({
  loader: () => import('./views/Pages/Page404'),
  loading
})

const Page500 = Loadable({
  loader: () => import('./views/Pages/Page500'),
  loading
})

class App extends Component {
  render() {
    return (
      <Router>
        <div>
          <Route path="/:path" component={GoogleAnalytics}/>
          <Switch>
            <Route exact path="/404" name="Page 404" component={Page404}/>
            <Route exact path="/500" name="Page 500" component={Page500}/>
            <Route path="/(:locale)?" name="Home" component={DefaultLayout}/>
          </Switch>
        </div>
      </Router>
    )
  }
}

export default App
