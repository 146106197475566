import React, {Component} from 'react'
import AirbrakeClient from 'airbrake-js'

export const airbrake = new AirbrakeClient({
  host: process.env.REACT_APP_AIRBRAKE_HOST,
  projectId: process.env.REACT_APP_AIRBRAKE_PROJECT_ID,
  projectKey: process.env.REACT_APP_AIRBRAKE_PROJECT_KEY
})

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {hasError: false}

    airbrake.addFilter(function (notice) {
      if (['development', 'test'].includes(process.env.NODE_ENV)) {
        // Ignore errors for dev environments
        return null
      }
      return notice
    })

    airbrake.addFilter(function(notice) {
      notice.context.environment = process.env.NODE_ENV
      return notice
    })

    if (process.env.NODE_ENV === 'development') {
      let methods = ['debug', 'log', 'info', 'warn', 'error']
      for (let m of methods) {
        if (m in console && console[m].inner) { // eslint-disable-line no-console
          console[m] = console[m].inner // eslint-disable-line no-console
        }
      }
    }
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({hasError: true})
    // Send error to Airbrake
    airbrake.notify({
      error: error,
      params: {info: info}
    })
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>
    }
    return this.props.children // eslint-disable-line
  }
}
