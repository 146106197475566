import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import translationEN from './locales/en/translations.json'
import translationPL from './locales/pl/translations.json'

const resources = {
  en: {
    translations: translationEN
  },
  pl: {
    translations: translationPL
  }
}

i18n.use(LanguageDetector).init({
  resources,
  detection: {
    order: ['path', 'cookie', 'localStorage']
  },
  fallbackLng: 'en',
  whitelist: ['pl', 'en'],
  debug: false,

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ','
  },

  react: {
    wait: false,
    withRef: false,
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'default'
  }
})

export default i18n
